import { Array, Boolean, Optional, Record, Static } from "runtypes";

import { FilledDynamicValueTableColumnTypeLiteral } from "../DynamicValue";
import { ExploreFieldTypeLiteral } from "../explore/types.js";
import { DisplayTableColumnId, SemanticDatasetName } from "../idTypeBrands";

import { ColumnPredicate } from "./columnPredicateTypes";

export const ColumnFilter = Record({
  column: DisplayTableColumnId,
  columnType: Optional(FilledDynamicValueTableColumnTypeLiteral),
  predicate: ColumnPredicate,
  // these fields below are relevant for filtering in explore cells
  // optional for backwards compatability
  queryPath: Optional(Array(SemanticDatasetName)),
  // for now, this should only be dimensions/columns, but in the future we may
  // support post-aggregation filters on meaures.
  fieldType: Optional(ExploreFieldTypeLiteral),
  // true if filters are active but should not show up in UI (e.g. filters copied over to explore)
  hidden: Optional(Boolean),
});
export type ColumnFilter = Static<typeof ColumnFilter>;

// graphql scalar
export type FilterPatternMap = { [columnId: string]: string };

export const TableFiltersDefinition = Array(ColumnFilter);
export type TableFiltersDefinition = Static<typeof TableFiltersDefinition>;
